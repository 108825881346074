import React, { useState } from "react";
import { Alert, Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import baseApi from "../apis/baseApi";
import FadeIn from "react-fade-in";
import Login from "./Login";
import logo from "../assets/logo1.png";

const Registration = () => {
  const [success, setSuccess] = useState(null);
  const [showLogin, setShowLogin] = useState(false);
  const [alertMessage, setAlertMessage] = useState(
    "Usuario creado exitosamente."
  );

  const renderAlert = () => {
    if (success === null) return <React.Fragment />;
    return (
      <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>
    );
  };

  if (showLogin) {
    return <Login setShowLogin={setShowLogin} />;
  }

  return (
    <Row className="h-100 text-center">
      <Col className="h-100 text-center">
        <div className="h-100 d-flex justify-content-center align-items-center">
          <Card className="shadow text-center p-5 customCard">
            <FadeIn>
              <Formik
                initialValues={{ username: "", password: "", re_password: "" }}
                validate={values => {
                  if (values.password.length < 6) {
                    return {
                      password: "La contraseña debe ser mayor a 6 caracteres."
                    };
                  }
                  if (values.password !== values.re_password) {
                    return { re_password: "Contraseñas no coinciden" };
                  }
                  return {};
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSuccess(null);
                  baseApi
                    .post("/auth/users/", { ...values, email: values.username })
                    .then(response => {
                      localStorage.setItem(
                        "access",
                        response.data.tokens.access
                      );
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                      setSuccess(true);
                      setAlertMessage("Usuario creado exitosamente");
                    })
                    .catch(err => {
                      console.log(err.response.data);
                      const error = err.response.data;
                      let message = error[Object.keys(error)[0]][0];
                      if (error["detail"]) {
                        message = error["detail"];
                      }
                      setSuccess(false);
                      setAlertMessage("Error: " + message);
                      setSubmitting(false);
                    });
                }}
              >
                {({ isSubmitting }) => (
                  <FormikForm>
                    <div
                      style={{
                        width: "200px",
                        display: "block",
                        margin: "auto"
                      }}
                      className="text-center"
                    >
                      <Image
                        src={logo}
                        fluid
                        className="img-responsive center-block"
                      />
                    </div>
                    <h3>Regístrate</h3>
                    <p>Para contratar tu plan</p>
                    {renderAlert()}
                    <div className="mt-5">
                      <Form.Group className="text-left">
                        <Form.Label>E-Mail</Form.Label>

                        <Field className="form-control" name="username" />
                      </Form.Group>
                      <Form.Group className="text-left">
                        <Form.Label>Contraseña</Form.Label>
                        <Field
                          type="password"
                          className="form-control"
                          name="password"
                        />
                        <div className="text-danger">
                          <ErrorMessage name="password" />
                        </div>
                      </Form.Group>
                      <Form.Group className="text-left">
                        <Form.Label>Confirmar contraseña</Form.Label>
                        <Field
                          type="password"
                          className="form-control"
                          name="re_password"
                        />
                        <div className="text-danger">
                          <ErrorMessage name="re_password" />
                        </div>
                      </Form.Group>
                    </div>
                    <div>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        className="px-5"
                      >
                        Registrarse
                      </Button>
                    </div>
                    <div
                      onClick={() => setShowLogin(true)}
                      className="registrarse-text"
                    >
                      ¿Ya tienes cuenta? Iniciar Sesión
                    </div>
                  </FormikForm>
                )}
              </Formik>
            </FadeIn>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default Registration;
