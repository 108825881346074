import React from "react";
import { Container } from "react-bootstrap";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";

import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/style.css";

const App = () => {
  return (
    <Container>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Container>
  );
};

export default App;
