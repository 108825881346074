import React, { useState } from "react";
import baseApi from "../apis/baseApi";
import { Alert, Button, Card, Form } from "react-bootstrap";

const RecoverPassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [newPassConfirm, setNewPassConfirm] = useState("");
  const [success, setSuccess] = useState(null);
  const [alertMessage, setAlertMessage] = useState("Error");

  const handleSubmit = e => {
    e.preventDefault();
    setSuccess(null);
    if (newPass.length < 6) {
      setSuccess(false);
      setAlertMessage("La contraseña no puede ser menor a 6 caracteres");
      return;
    }
    if (newPass !== newPassConfirm) {
      setSuccess(false);
      setAlertMessage("Las contraseña no coinciden");
      return;
    }
    setIsSubmitting(true);
    const urlParams = new URLSearchParams(window.location.search);

    if (newPass === newPassConfirm) {
      baseApi
        .post("/auth/users/reset_password_confirm/", {
          uid: urlParams.get("uid"),
          token: urlParams.get("token"),
          new_password: newPass
        })
        .then(() => {
          setAlertMessage("Contraseña actualizada correctamente.");
          setSuccess(true);
        })
        .catch(err => {
          setAlertMessage("Ocurrió un error.");
          setSuccess(false);
          setIsSubmitting(false);
        });
    } else {
      setSuccess(false);
      setAlertMessage("Las contraseñas no coinciden");
    }
  };

  const renderMessage = () => {
    if (success === null) return <React.Fragment />;
    return (
      <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>
    );
  };

  return (
    <div className="h-100">
      <div className="d-flex justify-content-center align-items-center h-100">
        <Card className="text-center">
          <Card.Header>Nueva Contraseña</Card.Header>
          <Card.Body>
            <h6 className="text-secondary mb-5">Restaurar Contraseña </h6>
            {renderMessage()}
            <Form onSubmit={handleSubmit} className="px-5">
              <div>
                <Form.Group className="">
                  <Form.Label>Nueva contraseña</Form.Label>
                  <Form.Control
                    type="password"
                    value={newPass}
                    onChange={event => setNewPass(event.target.value)}
                  />
                </Form.Group>
              </div>
              <div>
                <Form.Group>
                  <Form.Label>Confirmar nueva contraseña</Form.Label>
                  <Form.Control
                    type="password"
                    value={newPassConfirm}
                    onChange={event => setNewPassConfirm(event.target.value)}
                  />
                </Form.Group>
              </div>
              <Button type="submit" disabled={isSubmitting}>
                Confirmar nueva contraseña
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default RecoverPassword;
