import React, { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import queryString from "query-string";
import baseApi from "../apis/baseApi";
import jwtDecode from "jwt-decode";

const Success = () => {
  const params = queryString.parse(window.location.search);
  const [portalUrl, setPortalUrl] = useState("");

  useEffect(() => {
    const access = localStorage.getItem("access");
    const headers = { Authorization: "Bearer " + access };
    const decodedToken = jwtDecode(access);
    baseApi
      .get(`/get-session/?sessionId=${params.session_id}`, { headers: headers })
      .then(resp => {
        baseApi
          .patch(
            `/update-customer/${decodedToken.user_id}/`,
            { stripe_customer_id: resp.data.customer },
            { headers: headers }
          )
          .then(() => {
            baseApi.get("/get-portal/", { headers: headers }).then(resp => {
              setPortalUrl(resp.data.url);
            });
          });
      });
  }, [params]);

  return (
    <div>
      <Alert className="text-center p-4" variant="success">
        ¡Se ha iniciado la suscripción con éxito! <br />{" "}
        <div className="mt-4">
          <Button
            onClick={() => window.location.replace(portalUrl)}
            variant="outline-secondary"
            className="mr-3 "
          >
            Ir al portal de suscripciones
          </Button>
          <Button
            onClick={() => window.location.replace("https://aguapremier.com")}
          >
            Regresar a AguaPremier.com
          </Button>
        </div>
      </Alert>
    </div>
  );
};

export default Success;
