import React, { useState } from "react";
import { Card, Col, Row, Form, Button, Image, Alert } from "react-bootstrap";
import { Formik, Field, Form as FormikForm } from "formik";

import logo from "../assets/logo1.png";
import FadeIn from "react-fade-in";
import baseApi from "../apis/baseApi";
import ResetPasswordModal from "./ResetPasswordModal";

const Login = ({ setShowLogin }) => {
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [success, setSuccess] = useState(null);
  const [alertMessage, setAlertMessage] = useState(
    "Usuario creado exitosamente."
  );

  const renderAlert = () => {
    if (success === null) return <React.Fragment />;
    return (
      <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>
    );
  };

  return (
    <Row className="h-100">
      <ResetPasswordModal
        show={showResetPasswordModal}
        onHide={() => setShowResetPasswordModal(false)}
      />
      <Col className="h-100">
        <div className="h-100 d-flex justify-content-center align-items-center">
          <Card className="shadow text-center p-5 customCard">
            <FadeIn>
              <Formik
                initialValues={{ username: "", password: "" }}
                onSubmit={(values, { setSubmitting }) => {
                  setSuccess(null);
                  baseApi
                    .post("/auth/jwt/create/", values)
                    .then(resp => {
                      localStorage.setItem("access", resp.data.access);
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch(err => {
                      const error = err.response.data;
                      let message = error[Object.keys(error)[0]][0];
                      if (error["detail"]) {
                        message = error["detail"];
                      }
                      setAlertMessage("Error: " + message);
                      setSuccess(false);
                      setSubmitting(false);
                    });
                }}
              >
                {({ isSubmitting }) => (
                  <FormikForm>
                    <div
                      style={{
                        width: "200px",
                        display: "block",
                        margin: "auto"
                      }}
                      className="text-center"
                    >
                      <Image
                        src={logo}
                        fluid
                        className="img-responsive center-block"
                      />
                    </div>
                    <div>Agua Premier Suscripciones</div>
                    {renderAlert()}
                    <div className="mt-5">
                      <Form.Group className="text-left">
                        <Form.Label>E-Mail</Form.Label>
                        <Field className="form-control" name="username" />
                      </Form.Group>
                      <Form.Group className="text-left">
                        <Form.Label>Contraseña</Form.Label>
                        <Field
                          type="password"
                          className="form-control"
                          name="password"
                        />
                      </Form.Group>
                      <div
                        onClick={() => setShowResetPasswordModal(true)}
                        className="text-right text-secondary forgot-pass-text pb-3"
                      >
                        ¿Olvidaste tu contraseña?
                      </div>
                    </div>
                    <div>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        className="px-5"
                      >
                        Iniciar Sesión
                      </Button>
                    </div>
                    <div
                      onClick={() => setShowLogin(false)}
                      className="registrarse-text"
                    >
                      ¿No tienes cuenta? Registrarse aquí
                    </div>
                  </FormikForm>
                )}
              </Formik>
            </FadeIn>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default Login;
