import React, { useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import baseApi from "../apis/baseApi";

const ResetPasswordModal = ({ show, onHide }) => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(null);

  const handleSubmit = e => {
    e.preventDefault();
    setSuccess(null);
    baseApi
      .post("/auth/users/reset_password/", { email: email })
      .then(() => setSuccess(true))
      .catch(() => setSuccess(false));
  };

  const renderAlert = () => {
    if (success === null) return <React.Fragment />;
    return (
      <Alert variant={success ? "success" : "danger"}>
        {success
          ? "Se ha mandado un correo de recuperación al correo"
          : "Ocurrió un error"}
      </Alert>
    );
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header>Recuperar Contraseña</Modal.Header>
        <Modal.Body>
          {renderAlert()}
          <Form.Group>
            <Form.Label>Correo Electrónico</Form.Label>
            <Form.Control
              value={email}
              onChange={val => setEmail(val.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cerrar
          </Button>
          <Button type="submit">Confirmar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ResetPasswordModal;
