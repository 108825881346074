import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "./Login";
import Home from "./Home";
import Success from "./Success";
import RecoverPassword from "./RecoverPassword";

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/success" exact component={Success} />
      <Route path="/cancel" exact component={Login} />
      <Route path="/recover" exact component={RecoverPassword} />
    </Switch>
  );
};

export default Routes;
