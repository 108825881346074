import React, { useEffect, useRef, useState } from "react";
import jwtDecode from "jwt-decode";
import { loadStripe } from "@stripe/stripe-js";
import queryString from "query-string";

import baseApi from "../apis/baseApi";
import Registration from "./Registration";

const Home = () => {
  const ref = useRef({ stripe: null });
  const params = queryString.parse(window.location.search);
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  useEffect(() => {
    async function initialLoad() {
      ref.current.stripe = await loadStripe(
        "pk_live_51IF7H9KEcV5pmwtODBbQONmksQth7CBfaTFl3708AY5Z20mujYStg3xmcsrhGpy55Se0hJTE0l2rh2wKRjsH6rna00eL9CUuyq"
      );
    }

    initialLoad().then();
    const access = localStorage.getItem("access");
    if (access !== null) {
      const accessExpiration = jwtDecode(access).exp;
      const token_expiration = new Date(accessExpiration * 1000);
      if (token_expiration > new Date()) {
        setUserLoggedIn(true);
      }
    }
  }, []);

  useEffect(() => {
    if (userLoggedIn) {
      switch (params.action) {
        case "create-checkout":
          baseApi
            .post(
              "/create-session/",
              { priceId: params.priceId },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access")
                }
              }
            )
            .then(resp => {
              ref.current.stripe
                .redirectToCheckout({ sessionId: resp.data.id })
                .then(resp => console.log(resp));
            });
          break;
        case "go-to-portal":
          baseApi
            .get("/get-portal/", {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("access")
              }
            })
            .then(resp => {
              console.log(resp.data.url);
              window.location.replace(resp.data.url);
            })
            .catch(err => {
              console.log(err.response.data);
              const error = err.response.data;
              let message = error[Object.keys(error)[0]][0];
              if (error["detail"] !== null) {
                message = error["detail"];
              }
              alert("Error: " + message);
            });
          break;
        default:
          break;
      }
    }
  }, [userLoggedIn, params]);

  if (!userLoggedIn) {
    return <Registration />;
  }
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      Preparando pagina...
    </div>
  );
};

export default Home;
